import Service from "../Service";
import Vue from "vue";
const resource = "personVisit/";

export default {

    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    massiveSave(list) {
        console.log(list);   
        return Service.post( resource+"saveMassive",list);
    },

    searchByDocAndDate(obj, requestID) {
        console.log('obj', obj);
        return Service.post(resource + "searchByDocAndDate", obj, {
            params: { requestID: requestID },
        });
    },

}