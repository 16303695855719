<template  scope="props">
    <v-container>
        <v-row style="height :'700px'" >
            <v-col >
            <s-toolbar close   
                no-border  
                label="Visitas - Carga Masivos" 
                @close="$emit('EndProcess', null)"
                dark
                color="#2955c8"
                
             >

            </s-toolbar>
			     
				<v-card >	
					<v-col>
					<v-row>
						<v-col cols="6" md="2" lg="2">
							<s-toolbar-person readonly noCamera  @returnPerson="returnEncargado($event)" />
						</v-col>
						<v-col cols="6" md="4" lg="4">
							<s-text readonly label="Encargado" v-model="EncargadoName"></s-text>
							<s-text hidden v-model="IDEnc"></s-text>
							<s-text hidden v-model="DocEn"></s-text>
						</v-col>
						<v-col>
							<s-select-area v-model="areVisit" label="Área"   />
						</v-col>
						<v-col>
							<s-text
								label="Motivo"
                            v-model="motivoVisit"
							></s-text>
						</v-col>
					</v-row>
					</v-col>
					<v-col class="s-col-form">
						<v-row>
						<v-col >
              				<s-import-excel
                				@changeData="changeFile($event)"
								:headers = "headers"
								@loaded	="saveMassive($event)"
													
              				></s-import-excel>
			  			</v-col>
						</v-row>
                        
						<!--<v-row>
						<v-col  >
							<v-btn small="" block color="primary" @click="saveMassive(4)">
								Guardar
							</v-btn>
						</v-col>
						</v-row>-->
					</v-col>
				</v-card>

				<v-data-table v-if="PersonNoSave" item-key="Line" :headers="headerDetail" :items="itemsDataDetail">
              		<template v-slot:item.Actions="{ item }">
                		<v-btn
						color="teal darken-1"
						dark
						x-small
						@click="removeItem(item)"
						block
						>
                 	 	<v-icon small>mdi-minus-circle-outline</v-icon>
                		</v-btn>
              		</template>
            	</v-data-table>
				
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
	
	import _PersonService from "@/services/General/PersonService";
    import _PersonVisitService from '@/services/General/GenPersonVisitService.js';
    import _sDefinition from "@/services/General/DefinitionService.js";
	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import { VContainer } from "vuetify/lib";
import { filter } from "lodash";
	

	export default {
		components: {
			sToolbarPerson
		},

		props: {
			chamberSelected: null
		},

		data() {
            
			return {
				areVisit:0,
				motivoVisit:"",
				EncargadoName:"",
				DocEn:"",
				IDEnc:0,
				config: {
					model: {
						//PltID: "ID",
						//CocChange: ""
                        //TypeCompany
					},
					service: _PersonVisitService,
					headers: [
						
					]
				},
				filter:{
				},
				pltID: 0,
				areaId:0,
				PersonNoSave: false,
				dialogChange: false,
				headerDetail:[
					{ text: "DOCUMENTO", value: "DOCUMENTO", width: 60 },
					{ text: "APELLIDO_PATERNO", value: "APELLIDO_PATERNO", width: 60 },
					{ text: "APELLIDO_MATERNO", value: "APELLIDO_MATERNO", width: 60 },
					{ text: "NOMBRE", value: "NOMBRE", width: 60 },
					{ text: "EMPRESA", value: "EMPRESA", width: 60 },
					{ text: "FECHA_VISITA", value: "FECHA_VISITA", width: 60 },
				],
				itemsDataDetail: [],
                headers: [
					{
						column	: 	"DOCUMENTO",
						value	: 	"DOCUMENTO",
						text 	: 	"DOCUMENTO"
					},	
					{
						column	: 	"APELLIDO_PATERNO",
						value	: 	"APELLIDO_PATERNO",
						text 	: 	"APELLIDO_PATERNO"
					},
					{
						column	: 	"APELLIDO_MATERNO",
						value	: 	"APELLIDO_MATERNO",
						text 	: 	"APELLIDO_MATERNO"
					},	
					{
						column	: 	"NOMBRE",
						value	: 	"NOMBRE",
						text 	: 	"NOMBRE"
					},	
					{
						column	: 	"EMPRESA",
						value	: 	"EMPRESA",
						text 	: 	"EMPRESA"
					},	
					{
						column	: 	"FECHA_VISITA",
						value	: 	"FECHA_VISITA",
						text 	: 	"FECHA_VISITA",
						date	: 	true
					},			
				 ],
                person: [],
                documents: [],
                itemsCompany: [],
			}
		},

		methods: {
            
            saveMassive(items){
				debugger;
                let rpta=  "";
                let collection = [];
				let personNoSave = [];
                console.log("Items" , items);
                //this.$emit("EndProcess", rpta);
				if(this.areVisit==0 || this.motivoVisit=="" || this.EncargadoName=="" || this.IDEnc==0){
					this.$fun.alert("Todos los campos son obligatorios", "error");
				}else{
                this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
					if (val.value) {	
						console.log("ConvertidoPersona", this.person);
						console.log("ConvertidoPersonaEn", this.personEn);
                        

						items.forEach(element => {						
							let per = this.person.filter((pers) =>  (pers.GenPerson.PrsDocumentNumber).toString() === (element.DOCUMENTO).toString() );
							//let perEn = this.personEn.filter((pers) =>  (pers.GenPerson.PrsDocumentNumber).toString()  === (element.DOCUMENTO_ENC).toString());
							let company = this.itemsCompany.filter((comp) => comp.DedDescription === (element.EMPRESA).toUpperCase()  );
                            // aqui quiero capturar el dato area 
							//console.log("Person Filter",company[0]);						
							if(per[0]===undefined || company[0]===undefined ) { personNoSave.push(element)}
							else{
								collection.push({
									PrvDateVisit : element.FECHA_VISITA ,
									PrvDocumentNumber: element.DOCUMENTO,
									PrvDocumentNumberEn:this.DocEn.trim(),
									motivo:this.motivoVisit.trim(),
									SecStatus : 1,
									AreID:this.areVisit,
									NtpId: per[0]!=undefined ? per[0].NtpID : "" ,
									NtpIdEn:  this.IDEnc ,
									TypeCompany: company[0]!=undefined ? company[0].DedValue : "",
									UsrCreateID : this.$fun.getUserID()								
								});
							}
						});
						console.log("Convertido",collection);
						console.log("No registrado",personNoSave);
						//this.$emit('EndProcess', collection);
						_PersonVisitService.massiveSave(collection).then((r) => {
							if(r.status == 200) {
							this.$fun.alert("Agregados Correctamente", "success");
							this.clear();
							this.$emit('EndProcess', r.status);		
							this.$emit('EndProcess', collection);
							} 
							else { 
							this.$fun.alert("Ocurrio un error a realizar la carga", "error");
							this.$emit('EndProcess', null);
								
							}	
							
						})
					}
				})}		
            },
			returnEncargado(value) {
				console.log("Retornar Encargado", value);
				this.EncargadoName = value.NtpFullName; // Assuming the response has NtpFullName
				this.IDEnc = value.NtpID; // Assuming the response has NtpID
				this.DocEn=value.PrsDocumentNumber;
			},
            changeFile(data){        
				console.log('documento', this.documents)	       	
				this.documents = data;
				console.log("Datos anterirors person" , this.person)	
				this.person = this.searchPerson(this.documents);
				console.log("Datos posteriores person" , this.person)	
               // this.personEn = this.searchPersonEn(this.documents);
				//console.log("Datos posteriores personEn" , this.personEn)	
                this.searchCompany();
            },

			clear(){
				this.person = [];
				this.documents = [];
			},
			

            searchPerson(documents){ 
                let prs = [];
				documents.forEach(element => {
					_PersonService.getNaturalPerson(element.DOCUMENTO, this.$fun.getUserID()).then((r) => {
						let data  = r.data;
						if(data){						
						    //console.log("Persona encontrada", data);
                            prs.push(data)					                       
						}
						else{
							//console.log("No encontrado")
							let p = {
								NtpPaternalSurname : element.APELLIDO_PATERNO,
								NtpMaternalSurname : element.APELLIDO_MATERNO,
								NtpName : element.NOMBRE,
								TypeSex: 1,
								NtpBirthDate: "1900-01-01",
								GenPerson : {
									PrsDocumentNumber : element.DOCUMENTO,
									TypePerson : 1,
									TypePersonDocument : 1,
									NatID: 193,
								}
							}
						
							_PersonService.savenaturalperson(p,this.$fun.getUserID()).then((r) => {
								console.log("Registro de Persona", r.data);
								let per = r.data
								p = { 
									NtpID : per.usrID,
									GenPerson : {
										PrsDocumentNumber : element.DOCUMENTO,
									}
								};
								prs.push(p);
							})

						}
						
					})					
				});
                console.log("Listado de person", prs);
                return prs;            
            },
				
            searchCompany(){
            _sDefinition
						.definitiongroup(1471, 0, this.$fun.getUserID())
						.then((r) => {
							 this.itemsCompany = r.data;
							 console.log("caliber", this.itemsCompany);
                            })
            },
          
			/*searchPersonEn(documents){ 
                let prsEn = [];
				documents.forEach(element => {
					_PersonService.getNaturalPerson(element.DOCUMENTO_ENC, this.$fun.getUserID()).then((r) => {
						let data  = r.data;
						if(data){						
						    //console.log("Persona encontrada", data);
                            prsEn.push(data)					                       
						}
						else{
							//console.log("No encontrado")
							let p = {
								NtpPaternalSurname : element.APELLIDO_PATERNO_ENC,
								NtpMaternalSurname : element.APELLIDO_MATERNO_ENC,
								NtpName : element.NOMBRE_ENC,
								TypeSex: 1,
								NtpBirthDate: "1900-01-01",
								GenPerson : {
									PrsDocumentNumber : element.DOCUMENTO_ENC,
									TypePerson : 1,
									TypePersonDocument : 1,
									NatID: 193,
								}
							}
						
							_PersonService.savenaturalperson(p,this.$fun.getUserID()).then((r) => {
								console.log("Registro de Persona", r.data);
								let prsEn = r.data
								p = { 
									NtpID : prsEn.usrID,
									GenPerson : {
										PrsDocumentNumber : element.DOCUMENTO_ENC,
									}
								};
								prs.push(p);
							})

						}
						
					})					
				});
                console.log("Listado de person", prsEn);
                return prsEn;            
            },*/

		},

		
	}
</script>
